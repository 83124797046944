import request from "@/assets/javascripts/api/request"

export function checkSession(params) {
    return request({
        url: `/third-party/nebula/sessions`,
        method: 'GET',
        params
    })
}

export function signIn(data) {
    return request({
        url: `/third-party/nebula/sessions`,
        method: 'POST',
        data
    })
}

export function signOut() {
    return request({
        url: `/third-party/nebula/sessions`,
        method: 'DELETE'
    })
}