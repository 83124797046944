let OverviewPage = () => import(/* webpackChunkName: "Nebula" */'../components/Overview')
let HomePage = () => import(/* webpackChunkName: "Nebula" */'../components/HomePage')
let Layout = () => import(/* webpackChunkName: "Nebula" */'../components/layout/MainPageLayout')
let GroupDetailPage = () => import(/* webpackChunkName: "Nebula" */'../components/GroupDetailPage')
let CandidateAdminPage = () => import(/* webpackChunkName: "Nebula" */'../components/CandidatePage')
let MemberAdminPage = () => import(/* webpackChunkName: "Nebula" */'../components/MemberPage')
let SettingPage = () => import(/* webpackChunkName: "Nebula" */'../components/SettingPage')
let ReviewPage = () => import(/* webpackChunkName: "Nebula" */'../components/ReviewPage')
let VotePage = () => import(/* webpackChunkName: "Nebula" */'../components/VotePage')
let VoteDetailPage = () => import(/* webpackChunkName: "Nebula" */'../components/VoteDetailPage')
let ApplicationListPage = () => import(/* webpackChunkName: "Nebula" */'../components/ApplicationListPage')
let ApplicationDetailPage = () => import(/* webpackChunkName: "Nebula" */'../components/ApplicationDetailPage')
let SignInPage = () => import(/* webpackChunkName: "Nebula" */'../components/SignIn')
let SignUpPage = () => import(/* webpackChunkName: "Nebula" */'../components/SignUp')
let ForgetPage = () => import(/* webpackChunkName: "Nebula" */'../components/ForgetPage')
let ResetPasswordPage = () => import(/* webpackChunkName: "Nebula" */'../components/ResetPasswordPage')
let ApplicationFormPage = () => import(/* webpackChunkName: "Nebula" */'../components/ApplicationFormPage')

let routes = [{
    name: 'HomePage',
    path: '/',
    component: Layout,
    meta: {
        sign_in_required: true
    },
    children: [{
        name: 'OverviewPage',
        path: '',
        component: HomePage,
        meta: {
            sign_in_required: true
        },
    }, {
        name: 'ApplicationFormPage',
        path: 'application/:groupId/:applicationId',
        component: ApplicationFormPage
    }, {
        name: 'GroupDetailPage',
        path: 'groups/:groupId',
        component: GroupDetailPage,
        meta: {
            sign_in_required: true
        },
        children: [{
            name: 'CandidatePage',
            path: 'candidate',
            component: CandidateAdminPage,
            meta: {
                sign_in_required: true
            },
        }, {
            name: 'MemberPage',
            path: 'member',
            component: MemberAdminPage,
            meta: {
                sign_in_required: true
            },
        }, {
            name: 'ReviewPage',
            path: 'review',
            component: ReviewPage,
            meta: {
                sign_in_required: true
            },
        }, {
            name: 'VotePage',
            path: 'vote',
            component: VotePage,
            meta: {
                sign_in_required: true
            },
        }, {
            name: 'VoteDetailPage',
            path: 'vote/:voteId',
            component: VoteDetailPage,
            meta: {
                sign_in_required: true
            },
        }, {
            name: 'ApplicationListPage',
            path: 'application',
            component: ApplicationListPage,
            meta: {
                sign_in_required: true
            },
        }, {
            name: 'ApplicationDetailPage',
            path: 'application/:applicationId',
            component: ApplicationDetailPage,
            meta: {
                sign_in_required: true
            },
        }, {
            name: 'SettingPage',
            path: '',
            component: SettingPage,
            meta: {
                sign_in_required: true
            },
        }]
    }]
}, {
    name: 'SignInPage',
    path: '/sign_in',
    component: SignInPage
}, {
    name: 'SignUpPage',
    path: '/sign_up',
    component: SignUpPage
}, {
    path: '/password/forget',
    name: 'ForgetPasswordPage',
    component: ForgetPage
}, {
    path: '/password/reset',
    name: 'reset_password_page',
    component: ResetPasswordPage
}, {
    path: '*',
    redirect: '/sign_in'
}]

export default routes