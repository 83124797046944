import config from './config'
let md5 = require('js-md5')

export let actionMap = {
    admin: '管理',
    view: '浏览（参与）'
}

export let resourceMap = {
    setting: '基本信息',
    member: '成员',
    candidate: '项目',
    review: '点评',
    vote: '投票',
    application: '申报'
}

export function validate(user, request) {
    // 1. 系统管理员拥有全部权限
    if (user.isAdmin && !request.userOnly) {
        return true
    }
    // 2. guestOnly仅限游客访问
    if ( request.guestOnly ) {
        return !user
    }
    // 3. 游客除外都可以
    if ( request.noGuest ) {
        return user
    }
    // 4. 仅限外部用户（激活状态的）
    if ( request.userOnly ) {
        return user && !user.isAdmin
    }
    // 5. 仅限管理员
    if (request.adminOnly) {
        return user && user.isAdmin
    }
    if (request.superOnly) {
        return user && user.isSuper
    }
    // 6. 单项权限，不限类型
    if (request.resources && !request.action) {
        if (!user) {
            return false
        }
        return user.isSuper || user.privileges[request.resources]
    }
    // 10. 单项权限
    if (request.resources && request.action) {
        return user.isSuper || (user.privileges[request.resources] && user.privileges[request.resources][request.action])
    }
    return false
}

export function g () {
    let times1 = Math.ceil(Math.random() * 10)
    let times2 = Math.ceil(Math.random() * 10)
    let result = config.accessToken, date = new Date().format('yyyyMMdd')
    for(let i = 0; i < times1; i++) {
        result = md5(result)
    }
    for(let i = 0; i < times2; i++) {
        date = md5(date)
    }
    return result + date
}

export function bookSerialType (value) {
    if (value && value.length === 13) {
        if (value.slice(0, 6) === '975742') {
            return '本站编号'
        }
        return 'ISBN'
    }
    else if (value && value.slice(0, 6) === '975742') {
        return '本站编号'
    }
    return '统一书号'
}

// 通用的保存文件的方法
export function downloadFile(name, blob) {
    const d = document.createElement("a")
    d.href = window.URL.createObjectURL(blob)
    d.download = name
    d.style.display = "none"
    document.body.appendChild(d)
    d.click()
    document.body.removeChild(d)
    window.URL.revokeObjectURL(d.href)
}