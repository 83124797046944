
export default {
    basic: {
        slogan: '我们见证，我们记录，我们讲述',
        name: '中文科幻数据库',
        about: '关于 CSFDB',
        privacy: '隐私政策',
        weibo: '微博'
    },
    dictionaries: {
        basic: {
            all: '全部',
            person: '人物',
            work: '作品',
            series: '系列',
            book: '图书',
            omnibus: '书系',
            magazine: '杂志',
            issue: '期次',
            platform: '网媒',
            organization: '组织',
            award: '奖项',
            tag: '主题',
            keyword: '术语'
        },
        workType: {
            story: '中短篇小说',
            novel: '长篇小说',
            nonFiction: '非小说',
            小说: '小说',
            非虚构: '非虚构',
            '漫画/连环画': '漫画/连环画',
            访谈: '访谈',
            剧本: '剧本',
            诗歌: '诗歌',
            书信: '书信',
            编目: '编目',
            其他: '其他',
            画集: '画集'
        },
        workLength: {
            长篇: '长篇小说',
            中短篇: '中短篇小说'
        },
        gender: {
            男: '男',
            女: '女',
            其他: '其他'
        },
        region: {
            foreign: '海外',
            chinese: '本土'
        },
        bookType: {
            小说单行本: '小说单行本',
            作品选集: '作品选集',
            个人作品集: '个人作品集',
            杂志书: '杂志书',
            教材教辅: '教材教辅',
            诗集: '诗集',
            '论文/杂文': '论文/杂文',
            '周边/设定': '周边/设定',
            画集: '画集:',
            图像小说: '图像小说',
            传记: '传记',
            剧本: '剧本',
            '合订本/套装书': '合订本/套装书',
            概念书: '概念书',
            编目: '编目',
            缩编集: '缩编集'
        },
        imported: {
            yes: '引进',
            no: '非引进'
        },
        genre: {
            yes: '类型',
            no: '非类型'
        },
        juvenile: {
            yes: '少儿',
            no: '非少儿'
        },
        mainland: {
            yes: '大陆出版',
            no: '港澳台出版'
        },
        magazineType: {
            printed: '实体',
            digital: '电子'
        },
        magazinePeriod: {
            日报: '日报',
            周刊: '周刊',
            旬刊: '旬刊',
            半月刊: '半月刊',
            月刊: '月刊',
            双月刊: '双月刊',
            季刊: '季刊',
            半年刊: '半年刊',
            年刊: '年刊:',
            不定刊: '不定刊'
        },
        orgType: {
            出版社: '出版社',
            文化组织: '文化组织',
            爱好者社团: '爱好者社团',
            研究机构: '研究机构',
            杂志社: '杂志社',
            政府机关: '政府机关',
            社会组织: '社会组织',
            影视公司: '影视公司',
            漫画公司: '漫画公司',
            游戏公司: '游戏公司'
        },
        language: {
            chinese: '中文',
            english: '英文'
        },
    },
    actions: {
        search: '搜索',
        login: '登录',
        register: '注册',
        fullText: '查看全文'
    },
    models: {
        person: {
            name: '姓名',
            realName: '真名',
            gender: '性别',
            foreignName: '外文名',
            country: '国家/地区',
            bornAt: '出生时间',
            birthPlace: '出生地',
            diedAt: '去世时间',
            deathPlace: '去世地',
            resume: '人物介绍',
            pseudonym: '笔名/别名',
            themes: '作品流派'
        },
        work: {
            title: '标题',
            author: '作者',
            publishedAt: '发表时间',
            alias: '别名',
            coAuthor: '合著者',
            type: '类型',
            series: '所属系列',
            textLength: '字数',
            length: '篇幅',
            genre: '推想相关',
            juvenile: '少儿向',
            foreignOnly: '只看外译',
            foreignTitle: '外文名',
            platform: '发表平台',
            originalLanguage: '原始语言',
            firstPublishedAt: '首发时间',
            firstImportedAt: '中文首发时间',
            firstSimplifiedImportedAt: '简中首发时间',
            synopsis: '内容梗概',
            introduction: '附加说明',
            themes: '涉及概念',
            relationExplanation: '与本作关系',
            issue: "出现期次",
            more: "其他信息"
        },
        series: {
            name: '名称',
            originalName: '外文名',
            series: '从属系列',
            themes: '涉及概念',
            works: '包含作品',
            direct: '直属作品',
            rank: '序号'
        },
        book: {
            title: '书名',
            type: '类型',
            douban: '豆瓣编号',
            page: '页数',
            omnibus: '从属书系',
            description: '图书说明',
            themes: '涉及概念',
            contributors: '参与人员',
            works: '收录作品',
            issue: '从属报刊',
            binding: '装帧',
            imported: '引进/非引进',
            genre: '类型/非类型',
            publication: '出版信息',
            reader: '人群',
            region: '出版地区',
            mainland: '大陆/港澳台',
            publishedAt: '出版时间',
            publisher: "出版方",
            role: '工作',
            braille: '盲文'
        },
        omnibus: {
            publisher: '出品方',
            bookCount: '包含图书',
            books: '包含图书',
            contributors: '参与人员',
        },
        bookseries: {
            publisher: '出品方',
            bookCount: '包含图书',
            books: '包含图书',
            contributors: '参与人员',
        },
        magazine: {
            period: '发行周期',
            type: '杂志类型',
            professionalOnly: '只看专业杂志',
            genreOnly: '只看核心杂志',
            publisher: '出品方',
            precursor: '前身',
            introduction: '杂志介绍',
            issues: '历史期次',
            works: '发表作品',
        },
        issue: {
            overallNumber: '总期号',
            issueNumber: '期名',
            publishedAt: '发行时间',
            introduction: '附加说明',
            themes: '涉及概念',
            works: '发表作品'
        },
        platform: {
            name : '名称',
            startAt: '创办时间',
            host: '主办方',
            introduction: '平台介绍',
            works: '收录作品'
        },
        onlineplatform: {
            name : '名称',
            startAt: '创办时间',
            host: '主办方',
            introduction: '平台介绍',
            works: '收录作品'
        },
        organization: {
            type: '组织类型',
            foreign: '国内/国外',
            region: '国家地区',
            foundedAt: '创办时间',
            address: '地址',
            site: '官方网站',
            introduction: '组织介绍',
            members: '成员名录',
            relations: '合作关系',
            works: '直属作品',
            books: '旗下图书',
            omnibus: '旗下书系',
            magazines: '旗下杂志',
            awards: '所获奖项',
            platforms: '旗下网媒',
            hostedAwards: '主办奖项',
            cooperators: '合作方'
        },
        award: {
            active: '举办时间',
            period: '颁奖周期',
            host: '主办方',
            startAt: '创办年份',
            closeAt: '停办年份',
            introduction: '奖项介绍',
            records: '获奖记录'
        },
        tag: {
            foreignName: '外文名',
            relation: '相关主题',
            introduction: '介绍',
            works: '相关作品',
            glossary: '术语表'
        },
        theme: {
            foreignName: '外文名',
            relation: '相关主题',
            introduction: '介绍',
            works: '相关作品',
            glossary: '术语表'
        },
        keyword: {
            related: '出现条目',
            foreignName: '外文名'
        },
        publication: {
            info: '发表情况',
            type: '收录/发表情况',
            alias: '收录用名',
            date: '发表时间',
            contributors: '相关人员'
        },
        export: {
            target: '去向',
            publishedAt: '时间',
            language: '语言',
            contributors: '相关人员'
        }
    },
    sorters: {
        person: {
            alphabet: '按姓氏字母',
            birth: '按出生时间'
        },
        work: {
            publication: '首发时间',
            import: '中文首发时间',
            import_simplified: '简中首发时间',
            influence: '影响力',
            magazine_publication: '当刊首发时间',
        },
        book: {
            publication: '出版时间'
        },
        issue: {
            publication: '发行时间'
        },
        platform: {
            publication: '发行时间'
        }
    },
    labels: {
        basic: {
            all: '全部',
            default: '默认',
            order: '排序',
            yes: '是',
            no: '否',
            nothing: '暂无',
            none: '无',
            now: '今',
            unknown: '未知',
            empty: 'Nothing Here :b',
            links: '相关链接',
            contributors: '贡献者',
            lastEditAt: '最后编辑时间',
            lastEditByAdminAt: '最后编辑时间（管理员）',
            lastEditByAdmin: '管理员',
            relations: '关联条目',
            actions: '操作',
            noText: '暂无原文',
            awards: '获奖记录',
            relatedPublications: '相关出版物',
            similarWork: '含当前书刊中的作品',
            similarAuthor: '含作者的其他作品',
            similarTheme: '含标签类似的作品'
        },
        search: {
            emptyQuery: '请指定搜索关键词',
            nothing: '暂无详细介绍'
        },
        home: {
            newBook: '新书上架',
            recentWork: '近期新作',
            stat: '数据统计',
            birthday: '近期生日',
            awardCalendar: '奖项日历'
        },
        person: {
            none: '无',
            etc: '等',
            unknown: '未知',
            list: '人物列表',
            detail: '人物详情',
            juvenile: '少儿：{count}/{total}',
            died: '已故',
            workStat: '创作统计',
            timeline: '时间轴',
            works: '作品年表',
            derivatives: '相关作品（采访等）',
            other: '其他工作（翻译、缩写等）',
            awards: '获奖记录',
            publications: '相关出版物',
            occupations: '人物组织关系',
            editBook: '编辑图书',
            nonGenre: '非类型作品',
            awardedFor: '获奖作品',
            foreign: "外译情况"
        },
        work: {
            list: '作品列表',
            detail: '作品详情',
            translated: '有外译',
            juvenile: '少儿',
            nonGenre: '本作品非推想/推想相关作品',
            publications: '发表情况',
            book: '图书收录',
            magazine: '杂志发表',
            platform: '网络发表',
            derivatives: '相关作品（评论等）',
            export: '海外译出',
            scanCode: '手机扫描二维码阅读',
            awards: '获奖记录',
            topics: '相关专题'
        },
        series: {
            list: '系列列表',
            detail: '系列详情',
            derivatives: '相关作品（评论等）',
        },
        book: {
            list: '图书列表',
            detail: '图书详情',
            gat: '港澳台',
            juvenile: '少儿',
            nonGenre: '非类型',
            derivatives: '相关作品（评论等）',
            firstPublication: '首次发表',
            braille: '盲文'
        },
        omnibus: {
            list: '书系列表',
            detail: '书系详情',
        },
        magazine: {
            list: '杂志列表',
            detail: '杂志详情',
            year: '年份'
        },
        issue: {
            detail: '期次详情',
            prev: '上一期',
            next: '下一期',
            derivatives: '相关作品（评论等）',
            firstPublication: '首次发表',
        },
        platform: {
            list: '网媒列表',
            detail: '网媒详情',
            foundedAt: '{year}年创办',
            noFoundedAt: '创办时间未知',
            derivatives: '相关作品（评论等）',
            year: '年份',
        },
        organization: {
            list: '组织列表',
            detail: '组织详情',
            stat: '综合统计',
            history: '历年图书出版情况'
        },
        award: {
            activePeriodNow: '{year}年至今',
            activePeriodAll: '{start}年~{end}年',
            unknown: '未知',
            win: '获奖',
            fall: '提名',
            categoryView: '项目视图',
            sessionView: '届次视图',
            byCategory: '按子项筛选',
            bySession: '按届次筛选'
        },
        theme: {
            more: '详见对应数据库条目'
        },
        topic: {
            list: '专题列表',
            detail: '专题详情'
        },
        keyword: {
            alphabet: '字母表'
        }
    },
    forms: {
        search: {
            general: {
                placeholder: '请输入搜索关键词'
            },
            person: {
                placeholder: '请输入姓名或笔名'
            },
            work: {
                placeholder: '请输入作品名称'
            },
            series: {
                placeholder: '请输入系列名称'
            },
            book: {
                placeholder: '请输入书名或书号'
            },
            omnibus: {
                placeholder: '请输入书系名称'
            },
            magazine: {
                placeholder: '请输入杂志名称'
            },
            platform: {
                placeholder: '请输入网媒名称'
            },
            organization: {
                placeholder: '请输入组织名称'
            },
            award: {
                placeholder: '请输入奖项名称'
            }
        }
    }
}
